import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink, PageProps } from "gatsby"
import { FaCalendar, FaPhone } from "react-icons/fa"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import Layout from "../components/layout"
import BlockHeader from "../components/blockHeader"
import FAQVideo from "../components/faqVideo"
import { CoreValues, ICoreValue } from "../data/coreValues"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

interface InRedProps extends TextProps {
  children: string
}

function InRed({ children, ...rest }: InRedProps) {
  return (
    <chakra.span color="red.500" {...rest}>
      {children}
    </chakra.span>
  )
}

function Values({ location }: PageProps): JSX.Element {
  gsap.registerPlugin(ScrollTrigger)

  const valueRefs = React.useRef<HTMLDivElement[]>([])
  const iconRefs = React.useRef<HTMLDivElement[]>([])

  React.useEffect(() => {
    iconRefs.current.forEach(ref => {
      gsap.to(ref, {
        duration: 0.75,
        ease: `power2`,
        repeat: -1,
        scale: 1.1,
        scrollTrigger: ref,
        yoyo: true,
      })
    })

    valueRefs.current.forEach((ref, index) => {
      if (index === 0) return
      gsap.from(ref, {
        opacity: 0.05,
        x: index % 2 ? `30%` : `-30%`,
        y: `10%`,
        scrollTrigger: {
          start: `top 65%`,
          trigger: ref,
        },
      })
    })
  }, [])

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Our relationships with our clients is at the heart of our work. When you build a home with us, you can be sure we have your best interests in mind.",
          image: `${location.origin}/sundog-team-2022-630x205.png`,
          imageAlt: `The Sundog Homes team`,
        }}
        title="Our Values, Custom Home Builders Who Care | Sundog Homes"
        url={location.href}
      />
      <Flex
        align="center"
        flexDir="column"
        justify="center"
        maxW="95vw"
        mb={24}
        mt={12}
        mx="auto"
        w="800px"
      >
        <BlockHeader>Our Values</BlockHeader>
        <Text
          className="decor"
          fontSize="5xl"
          fontWeight="extrabold"
          lineHeight="shorter"
          mt={4}
          textAlign="center"
        >
          A custom home can't be built with nails and wood alone.
        </Text>
      </Flex>
      <Flex align="center" flexDir="column" justify="center" w="100vw">
        {CoreValues.map((value: ICoreValue, index: number) => (
          <Box
            bgGradient={`linear-gradient(${
              index % 2 === 0 ? `30` : `150`
            }deg, gray.100 50%, white 50%)`}
            minH="calc(100vh - 4.5rem)"
            key={index}
            overflow="hidden"
            w="100vw"
          >
            <Flex
              align="center"
              flexDir="column"
              h="100%"
              justify="center"
              maxW="95vw"
              mx="auto"
              opacity="1"
              ref={el => valueRefs.current.push(el)}
              w="500px"
            >
              <Box mb={12} ref={el => iconRefs.current.push(el)}>
                <Icon as={value.icon} boxSize="120px" />
              </Box>
              <Box pos="relative" py={1}>
                <Text
                  color="red.200"
                  fontSize="50vh"
                  fontWeight="900"
                  pos="absolute"
                  left={index % 2 === 0 ? [`-50%`, `-40%`, `20%`] : ``}
                  right={index % 2 === 0 ? `` : [`-50%`, `-40%`, `20%`]}
                  opacity="0.2"
                  top="-10vh"
                  zIndex="1"
                >
                  #{index + 1}.
                </Text>
                <Text
                  className="decor"
                  color="red.500"
                  fontSize="4xl"
                  pos="relative"
                  zIndex="2"
                >
                  {value.title}
                </Text>
              </Box>
              <Box zIndex="2">
                <Text fontSize="xl" fontWeight="medium" mt={6} mx="auto">
                  {value.description}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Flex>
      <Box
        bgGradient="linear-gradient(150deg, gray.100 50%, gray.100 50%)"
        w="100vw"
      >
        <Flex
          align="start"
          flexDir="column"
          justify="center"
          maxW="95vw"
          mx="auto"
          pb={[24, , 48]}
          textAlign="center"
          w="800px"
        >
          <Box pb={24} pt={18} w="100%">
            <FAQVideo
              bg="white"
              question="How long has Sundog been in business? How have you navigated macro-economic cycles?"
              oEmbedUrl="https://sundoghrv.wistia.com/medias/n5qy8y1zkg?embedType=iframe&videoFoam=true&videoWidth=640"
              textAlign="left"
              videoDescription="David Earley discusses Sundog Homes' financial stability."
              w="100%"
            />
          </Box>
          <Heading as="h4" fontSize="5xl" fontWeight="bold">
            Finding the <InRed>right builder</InRed> isn't easy. But it's{" "}
            <InRed>absolutely</InRed> worth it.
          </Heading>
          <Text color="gray.700" fontSize="lg" mt={6}>
            We've been around long enough to understand that the quality of the
            client-builder relationship affects the quality of the home. That's
            why our values are so client-focused, and that's why we live by them
            every day. Think we're a good match? We'd love to chat about your
            project — reach out whenever you're ready.
          </Text>
          <Stack
            align="center"
            direction={[`column`, `row`]}
            justify="center"
            mt={12}
            mx="auto"
            spacing="16px"
            w="100%"
          >
            <Button
              as={GatsbyLink}
              colorScheme="red"
              h={16}
              leftIcon={<Icon as={FaCalendar} />}
              size="lg"
              to="/schedule-consultation/"
              variant="solid"
              w={[`full`, `auto`]}
            >
              Schedule a Consult
            </Button>
            <Button
              as={Link}
              colorScheme="red"
              h={16}
              leftIcon={<Icon as={FaPhone} />}
              size="lg"
              href="tel:8287745720"
              variant="outline"
              w={[`full`, `auto`]}
            >
              (828) 774-5720
            </Button>
          </Stack>
        </Flex>
      </Box>
    </Layout>
  )
}

export default Values
